/**
 * Router service file
*/

// Async component
import {
	AsyncDashboard1Component,
	AsyncFormInformationComponent,
	AsyncUserMasterComponent,
	AsyncConfigurationMasterComponent,
	AsyncUserSettingsComponent,
	AsyncGalleryComponent,
	AsyncGalleryCategoryComponent,
} from 'routes/ComponentLoader';

const routes = [
	//Dashboard's routes & permissions.
	{
		path: 'dashboard',
		component: AsyncDashboard1Component,
		meta: { permission: true },
	},
	//Master's routes & permissions.
	{
		path: 'master/user',
		component: AsyncUserMasterComponent,
		meta: { permission: true },
	},

	{
		path: 'master/helpMaster',
		component: AsyncFormInformationComponent,
		meta: { permission: true },
	},
	{
		path: 'master/configuration',
		component: AsyncConfigurationMasterComponent,
		meta: { permission: true },
	},
	{
		path: 'user-settings',
		component: AsyncUserSettingsComponent,
		meta: { permission: true },
	},
	{
		path: 'master/gallery',
		component: AsyncGalleryComponent
	},
	{
		path: 'master/gallery-category',
		component: AsyncGalleryCategoryComponent
	}
	//    {
	// 		path: 'sample-form',
	//       component:AsyncSampleFormComponent
	// 	}
]

export default routes;