// ** Checks if an object is empty (returns boolean)
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AWS from "aws-sdk";
import firebase from "@firebase/app";
import { NotificationManager } from "react-notifications";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const MySwal = withReactContent(Swal);
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: "login session has been expired ?",
    text: "login again to continue accessing your account !",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Yes, logout it!",
    customClass: {
      confirmButton: "btn btn-danger",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      firebase.auth().signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase
    .auth()
    .currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      return Promise.reject(error);
    }
  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export { Axios, AxiosInstance };

//-------------------image upload on s3--------------------------//
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION,
});

const s3 = new AWS.S3({
  apiVersion: "2012-10-17",
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
});

// upload image on s3 bucket  directly
export const uploadImageToS3 = (file) => {
  const params = {
    Key: `${Date.now()}_${file.name}`,
    Body: file,
    ACL: 'public-read',
    ContentType: file.type
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        resolve("");
      } else {
        resolve(data.Location);
      }
    });
  });
};

// export const uploadImageToS3 = (file, setisLoader) => {
//   console.log("IMAGE FILE:- ", file);
//   const allowedExtensions = ["png", "jpg", "jpeg"];
//   const fileExtension = file.name.split(".").pop().toLowerCase();

//   if (!allowedExtensions.includes(fileExtension)) {
//     NotificationManager.error(
//       "Only JPG, PNG, JPEG file types are allowed",
//       "Error"
//     );
//     return Promise.resolve(""); // Return a resolved promise to handle the error
//   }
//   const params = {
//     Key: `${Date.now()}_${file.name}`,
//     Body: file,
//     ACL: "public-read",
//     ContentType: file.type,
//   };
//   setisLoader(true);
//   return new Promise((resolve, reject) => {
//     s3.upload(params, (err, data) => {
//       setisLoader(false);
//       if (err) {
//         NotificationManager.error("File upload failed", "Error");
//         resolve("");
//       } else {
//         // NotificationManager.success("File uploaded successfully", "Success");
//         resolve(data.Location);
//       }
//     });
//   });
// };

export const UPLOAD_IMAGES = async (
  event,
  setMediaFile,
  setShowPreview,
  setLoading
) => {
  setLoading(true); // Set loading to true when starting the upload

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false); // Set loading to false if the condition is not met
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false); // Set loading to false if the condition is not met
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "pdf"
    // event.target.accept.includes("video/") // Allow video files
  ) {
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      const isImage = ["png", "jpg", "jpeg"].includes(extension);
      // const isVideo = event.target.accept.includes("video/");

      if (
        isImage &&
        file.size <= 1000000
        // (isVideo && file.size <= 51000000)
      ) {
        const filePath = `vendor/${data.subfolderName
          }/${date}/${uuidv4()}.${extension}`;

        try {
          await s3
            .upload({
              Key: filePath,
              Body: file,
              ACL: "public-read",
            })
            .promise();
          const fileUrl = `https://relocare.s3.ap-south-1.amazonaws.com/${filePath}`;
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          setMediaFile(fileUrl);
          setShowPreview(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "File size needs to be less than or equal to 1mb",
          "File name: " + file.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      "Only JPG, PNG, JPEG, PDF, or video file types are allowed",
      "Error"
    );
  }

  setLoading(false); // Set loading to false when the upload is complete
};

export const deleteImageFromS3 = (filepath) => {
  const params = {
    Key: filepath,
  };

  return new Promise((resolve, reject) => {
    s3.deleteObject(params, (err, data) => {
      if (err) {
        resolve("");
      } else {
        resolve(data);
      }
    });
  });
};

export const UPLOAD_VIDEOS = async (
  event,
  setMediaFile,
  setShowPreview,
  setLoading
) => {
  setLoading(true);
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false);
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false);
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (event.target.accept.includes("video/")) {
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      const isVideo = event.target.accept.includes("video/");

      if (isVideo && file.size <= 51000000) {
        const filePath = `vendor/${
          data.subfolderName
        }/${date}/${uuidv4()}.${extension}`;

        try {
          await s3
            .upload({
              Key: filePath,
              Body: file,
              ACL: "public-read",
            })
            .promise();
          const fileUrl = `https://relocare.s3.ap-south-1.amazonaws.com/${filePath}`;
          NotificationManager.success("Video uploaded successfully", "Success");
          // You can use setState or some other state management mechanism to update UI
          setMediaFile(fileUrl);
          setShowPreview(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "Video size needs to be less than or equal to 50mb",
          "File name: " + file.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error("Only video file types are allowed", "Error");
  }

  setLoading(false);
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
