/**
 * Horizontal Menu
 */
import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import MenuItems from "routes/MenuItems";

import NavMenuItem from "./NavMenuItem";

function HorizontalMenu() {
  const [navLinks] = useState(MenuItems);
  const [general, setGeneral] = useState(null);
  // const [module,setModules] = useState(null);
  // const [component,setComponents] = useState(null);
  // const [application, setApplication] = useState(null);

  useEffect(() => {
    setCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCategory = () => {
    let category1 = navLinks.filter((item, i) => {
      return item.category === "general";
    });
    // let category2 = navLinks.filter((item, i) => {
    //   return item.category === "modules";
    // });
    // let category3 = navLinks.filter((item, i) => {
    //   return item.category === "components";
    // });
    // let category4 = navLinks.filter((item, i) => {
    //   return item.category === "applications";
    // });
    setGeneral(category1);
    // setModules(category2);
    // setComponents(category3);
    // setApplication(category4);
  };

  return (
    <Box className="horizontal-menu">
      <ul className="list-unstyled nav">
        <li className="nav-item menu-item-has-child">
          <span>
            <i className="material-icons-outlined">dashboard</i>
            <span className="menu-title">Dashboard</span>
          </span>
          <ul className="list-unstyled sub-menu">
            {general &&
              general.map((menu, key) => <NavMenuItem menu={menu} key={key} />)}
          </ul>
        </li>
      </ul>
    </Box>
  );
}

export default HorizontalMenu;
