const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    designationData: [],
    reportingManager: [],
    // change_PasswordData:[],
  }
  
  const configurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_CONFIGURATION':
            return {...state}
        case 'UPDATE_CONFIGURATION':
            return { ...state }
        case 'GET_DATA_CONFIGURATION_LIST':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }
        default:
            return { ...state }
      
    }
  }
  export default configurationReducer
  