
import { Axios } from '../../util/index'
import { NotificationManager } from 'react-notifications';

//-----------get user list with paginated data--------------------------// 
export const getHelpInformationList = (params, setisLoader) => {
    setisLoader(true)
    return dispatch => {
        Axios.get(`/api/master/helpMaster/paginated?page=${params.page}&limit=${params.perPage}&search=${params.search}`).then(response => {
            dispatch({
                type: 'GET_DATA_INFORMATION',
                allData: response.data.data.total,
                data: response.data.data.docs,
                totalPages: response.data.data.pages,
                params
            })
        })
            .catch(error => {
                dispatch({
                    type: 'GET_DATA_INFORMATION',
                    allData: 0,
                    data: [],
                    totalPages: 0,
                    params
                })
            }).finally(() => {
                setisLoader(false)
            })
    }
}


//-----------------create new information--------------------------//
export const createNewHelpInfo = (data, props, setisLoader, reset,setShowPreview) => {
    return (dispatch, getStore) => {
        Axios.post(`/api/master/helpMaster`, {
            url: data.url,
            title: data.title,
            video: data.video,
            note: data.note,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                }
                else {
                    props.onMasterSave();
                    reset()
                    setShowPreview(false)
                    NotificationManager.success(response.data.message);
                    return dispatch => {
                        dispatch(
                            {
                                type: 'CREATE_INFORMATION',
                                data: []
                            }
                        )
                    }
                }
            })

            .then(() => dispatch(getHelpInformationList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {
                NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');
            })
    }
}

// ------------------update information------------------------//
export const updateHelpInfo = (data, props, setisLoader) => {
    return (dispatch, getStore) => {
        Axios.put(`/api/master/helpMaster/${props.preloadValue.detail._id}`, {
            url: data.url,
            title: data.title,
            video: data.video,
            note: data.note,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);

                } else {
                    NotificationManager.success(response.data.message);
                    props.onMasterSave();
                    return dispatch => {
                        dispatch(
                            {
                                type: 'UPDATE_INFORMATION',
                                data
                            }
                        )
                    }
                }
            })
            .then(() => dispatch(getHelpInformationList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {

            })


    }
}


