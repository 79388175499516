import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Box, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { AxiosInstance } from "util/index";
// redux action
// import { signinUserWithFirebase } from "actions";
// import { signinUserWithFirebase } from "Redux/actions/AuthActions";
import { verifyOtp } from "Redux/actions/auth/index";
import { NotificationManager } from "react-notifications";
import { firebase, auth } from "./../../firebase";
function Otp(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const handleKeyPress = (event) => {
  //   const pattern = /[0-9]/; // Only allow numeric characters
  //   const inputChar = String.fromCharCode(event.charCode);
  //   if (!pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // };

  const settings = useSelector((state) => state.settings);
  // const [phone, setPhone] = useState(
  //   props &&
  //   props.location &&
  //   props.location.state &&
  //   props.location.state.mobile
  // );
  const [count, setCount] = useState(30);
  // const [password, setOtp] = useState("");

  useEffect(() => {
    setInterval(() => setCount((counter) => counter - 1), 1000);
  }, [setCount]);
  if (count === -1) {
    setCount(0);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [verifyIsLoading, setVerifyIsLoading] = useState(false);

  const onSubmit = (data) => {
    verifyOtp(data, props, setVerifyIsLoading);
  };

  const resend = (e) => {
    e.preventDefault();
    if (typeof props.location.state !== "undefined") {
      setIsLoading(true);
      // setCount(30);
      AxiosInstance.post(`/auth/checkMobileNumberExist`, {
        mobile_number: props.location.state.mobile,
      }).then((response) => {
        if (response && response.data && response.data.status === true) {
          // firebase.auth().settings.appVerificationDisabledForTesting = true;
          const verify = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              badge: "inline",
            }
          );
          auth
            .signInWithPhoneNumber(props.location.state.mobile, verify)
            .then((result) => {
              delete window.finalResult;
              window.finalResult = result;
              NotificationManager.success(
                "OTP sent successfully on your registered mobile"
              );
            })
            .catch((err) => {
              // alert(err);
              window.location.reload();
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          NotificationManager.error("Something Went Wrong !");
        }
      });
    } else {
      NotificationManager.error("Something Went Wrong !");
    }
  };

  const { isDarkModeActive } = settings;

  return (
    <div>
      <div className="session-wrapper">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={4} className="login-wrap">
            <div className="login-wrapper text-center">
              <div className="w-100">
                <div className="session-logo">
                  {isDarkModeActive ? (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="100"
                      src={require(`assets/Images/hulk-light.png`).default}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="100"
                      src={require(`assets/Images/hulk-dark.png`).default}
                    />
                  )}
                </div>
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      id="otp"
                      label="OTP"
                      name="login_otp"
                      placeholder="Enter the OTP received on your mobile number."
                      className={errors.login_otp ? "is-invalid" : ""}
                      type="password" // Use 'text' for showing actual characters for testing, you may switch it back to 'password' in production
                      {...register("login_otp", {
                        required: "OTP is required.",
                      })}
                    />
                    {errors.login_otp && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        {errors.login_otp.message}
                      </Box>
                    )}
                  </Box>

                  <Box mb="40px" pt="20px">
                    <div id="recaptcha-container"></div>
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      {verifyIsLoading ? (
                        <span>
                          <CircularProgress
                            color="light"
                            size={20}
                            style={{ marginBottom: "-3px" }}
                          />
                        </span>
                      ) : (
                        <span>Verify</span>
                      )}
                    </Button>
                  </Box>
                  <Box mb="40px" pt="20px">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <p>
                        {" "}
                        Didn't receive the OTP?{" "}
                        {count === 0 ? (
                          isLoading ? (
                            <span style={{ marginLeft: "5px" }}>
                              <CircularProgress size={15} />
                            </span>
                          ) : (
                            <span
                              onClick={(e) => resend(e)}
                              style={{
                                border: "none",
                                color: "blue",
                                cursor: "pointer",
                                padding: 0,
                              }}
                            >
                              Resend OTP
                            </span>
                          )
                        ) : (
                          <span
                            className="text-right mt-2"
                            style={{ color: "red" }}
                          >
                            {" "}
                            00:{count}
                          </span>
                        )}
                      </p>
                    </div>
                  </Box>
                </form>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            style={{
              backgroundImage:
                "url(" +
                require("assets/Images/session-forgot.jpg").default +
                ")",
              backgroundSize: "cover",
              backgroundPosition: "center left",
            }}
            className="img-session"
          >
            <div className="login-content">
              <Box
                fontSize="h1.fontSize"
                fontWeight="h1.fontWeight"
                mb={4}
                color="common.white"
              >
                Not To Worry, We Got You Covered
              </Box>
              <Button
                variant="contained"
                component={Link}
                to="/signin"
                className="btn-block-md"
              >
                Back To Sign In
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Otp;
